<template>
  <div class="page">
    <div class="narrowBox">
      <div class="left-menu">
        <userInfoCard
          :userinfo="data.baseInfo"
          @fource="fourceHandler"
          @errorRefsh="loadUserFunc()"
        />
        <ul class="menu">
          <li
            v-for="(nav, index) in menu"
            :key="index"
            :class="{ active: 3 === index }"
            @click="handlerMenuClick(index)"
          >
            <i :class="nav.icon"></i>
            <span v-if="nav.num !== undefined">{{ nav.num }}</span>
          </li>
        </ul>
      </div>
      <div class="right-content">
        <Services
          v-if="activeNum === 0"
          :data="servicesData"
          @delSuccess="delSuccessHandler"
          :active-num="0"
        />
        <CompanyGroup
          v-if="activeNum === 1"
          :data="groups"
          type="group"
          @delSuccess="delSuccessHandler"
          :active-num="1"
          :page="page"
        />
        <CompanyGroup
          v-if="activeNum === 2"
          :data="enters"
          type="company"
          @delSuccess="delSuccessHandler"
          :active-num="2"
          :page="page"
        />
        <BaseInfo
          v-if="activeNum === 3"
          :data="data"
          @change="baseinfoChangeHandler"
          :active-num="3"
          :fources="foruces"
        />
        <el-pagination
          v-if="activeNum !== 3"
          background
          layout="prev, pager, next"
          :page-size="pageSize"
          :hide-on-single-page="true"
          :total="total"
          :current-page="page"
          @current-change="pageChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import userInfoCard from "@/components/business/web-site/user-info-card";
import { publicDo } from "@/api/dado";
import BaseInfo from "@/components/business/web-site/user-center/baseinfo";
import Services from "@/components/business/web-site/user-center/services";
import CompanyGroup from "@/components/business/web-site/user-center/company_group";

export default {
  name: "User",
  data() {
    return {
      uid: 0,
      data: {},
      servicesData: {},
      groups: {},
      enters: {},
      baseInfo: {},
      activeNum: 0, //0 服务 1 群组 2 公司 3 基本信息
      page: 1,
      pageSize: 16,
      total: 0,
      menu: [
        { icon: "el-icon--document", num: 0 },
        { icon: "el-icon--group", num: 0 },
        { icon: "el-icon--company", num: 0 },
        { icon: "el-icon--userinfo" },
      ],
      showBtns: false,
      counts: {},
      language: 1,
      foruces: false,
    };
  },
  props: {
    active: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    watchUserinfo() {
      return this.$store.getters.userInfo;
    },
    watchId() {
      if (this.$route.params.id) {
        return this.$Base64.decode(this.$route.params.id.replace("pai_", ""));
      } else {
        return null;
      }
    },
  },
  watch: {
    watchUserinfo: {
      handler() {
        this.baseinfoChangeHandler();
      },
      dreep: true,
    },
    watchId: {
      handler(val) {
        if (val != null) {
          this.uid = val;
          this.loadUserFunc();
        }
      },
      dreep: true,
    },
  },
  created() {
    this.$store.dispatch("commitMenuStatus", false);
    this.language = window.localStorage.getItem("langCode") || 1;
    this.uid = this.$Base64.decode(this.$route.params.id.replace("pai_", ""));
    this.activeNum = this.$route.params.active
      ? parseInt(this.$route.params.active)
      : this.active;
    this.loadUserFunc();
  },
  components: {
    userInfoCard,
    BaseInfo,
    Services,
    CompanyGroup,
  },
  methods: {
    changeShowBtns() {
      this.showBtns =
        Object.keys(this.$store.getters.userInfo).length &&
        this.uid === this.$store.getters.userInfo.id
          ? true
          : false;
    },
    loadUserFunc() {
      this.getUserInfo();
      this.handlerMenuClick(this.activeNum);
      this.getDataCount();
      this.changeShowBtns();
    },
    fourceHandler(val) {
      // console.log(val);

      this.foruces = val.fource;
      this.activeNum = parseInt(val.active);
    },
    delSuccessHandler() {
      this.handlerMenuClick(this.activeNum);
    },
    baseinfoChangeHandler() {
      this.getUserInfo();
    },
    pageChange(page) {
      // console.log(page, "/page");
      this.page = page;
      this.handlerMenuClick(this.activeNum, page);
    },
    handlerMenuClick(key, page) {
      this.activeNum = key;
      this.jumpPage({
        name: "personalCenter",
        params: { id: this.uid, active: key },
      });
      this.page = page || 1;
      switch (key) {
        case 0:
          this.getUserServices();
          break;
        case 1:
          this.getUserGroup();
          break;
        case 2:
          this.getUserCompany();
          break;
        case 3:
          this.getUserInfo();
          break;
      }
      this.getDataCount();
    },
    async getUserCompany(page) {
      // userId: '查询人的id', providerId: 当前登录用户id
      let params = {
        language: this.language,
        userId: this.$store.getters.userInfo.id || "",
        providerId: this.uid,
        pageNum: page || this.page,
        pageSize: this.showBtns && this.page === 1 ? 15 : 16,
        categoryId: "",
      };
      let result = await this.$http({
        url: "rest/personalCenter/v3/getEnterpriseData",
        params,
        withCredentials: false,
      });
      this.total = result.total;
      this.enters = {
        items: this.formartEnters(result.data),
        showBtns: this.showBtns,
      };
    },
    async getUserGroup(page) {
      let params = {
        language: this.language,
        providerId: this.uid,
        userId: this.$store.getters.userInfo.id || "",
        groupCountMode: "all",
        pageNum: page || this.page,
        pageSize: this.showBtns && this.page === 1 ? 15 : 16,
      };
      let result = await this.$http({
        url: "rest/personalCenter/v3/getGroupData",
        params,
        withCredentials: false,
      });
      this.total = result.total;
      this.groups = {
        items: this.formartGruop(result.data),
        showBtns: this.showBtns,
      };
    },
    async getUserServices(page) {
      let params = {
        language: this.language,
        userId: this.$store.getters.userInfo.id || "",
        providerId: this.uid,
        pageNum: page || this.page,
        pageSize: this.showBtns && this.page === 1 ? 15 : 16,
      };
      let result = await this.$http({
        url: "rest/personalCenter/v3/getServicesData",
        params,
        withCredentials: false,
      });
      this.total = result.total;
      this.servicesData = {
        items: result.data,
        showBtns: this.showBtns,
      };
    },
    async getDataCount() {
      let params = {
        language: this.language,
        userId: this.$store.getters.userInfo.id || "",
        providerId: this.uid,
        groupCountMode: "all",
      };
      let result = await this.$http({
        url: "rest/personalCenter/v3/getDataCount",
        params,
        withCredentials: false,
      });
      this.counts = result.data[0];
      this.menu[0].num = this.counts.serviceCount;
      this.menu[1].num = this.counts.groupCount;
      this.menu[2].num = this.counts.enterpriseCount;
      // console.log(this.counts, "///get count");
    },
    async getUserInfo() {
      let params = {
        funcId: "hex_helome_getProfileInfoFunction",
        provider_id: this.uid,
        query_no_cache: 1,
        table_name_replace: localStorage.getItem("langCode"),
      };
      if (this.$store.getters.userInfo.id)
        params.userId = this.$store.getters.userInfo.id;
      let result = await publicDo(params, "public/data.do");
      // console.log(result.data[0], "/result.data[0]");
      if (result && result.data && result.data.length)
        this.data = this.formartInfo(result.data[0]);
    },
    formartEnters(arr) {
      let newArr = [];
      arr.forEach((element) => {
        let group = {
          avatar: element.enterprise_logo,
          name: element.enterprise_name,
          id: element.enterprise_id,
          desc:
            element.enterprise_info.length > 28
              ? element.enterprise_info.slice(0, 28)
              : element.enterprise_info,
          owner: element.enterprise_owner,
          tags:
            element.enterprise_tag && element.enterprise_tag.indexOf(",")
              ? element.enterprise_tag.split(",")
              : element.enterprise_tag !== ""
              ? [element.enterprise_tag]
              : [],
          actions: {
            id: element.enterprise_id,
            zanNum: element.like_count,
            replyCount: element.reviewer_count,
          },
        };
        newArr.push(group);
      });
      return newArr;
    },
    formartGruop(arr) {
      let newArr = [];
      arr.forEach((element) => {
        let group = {
          avatar: element.group_header,
          name: element.group_name,
          id: element.group_id,
          desc:
            element.group_info.length > 28
              ? element.group_info.slice(0, 28)
              : element.group_info,
          owner: element.group_owner,
          tags:
            element.group_tag && element.group_tag.indexOf(",")
              ? element.group_tag.split(",")
              : element.group_tag !== ""
              ? [element.group_tag]
              : [],
          actions: {
            id: element.group_id,
            zanNum: element.like_count,
            replyCount: element.reviewer_count,
          },
        };
        newArr.push(group);
      });
      return newArr;
    },
    formartInfo(data) {
      return {
        baseInfo: {
          name: data.name,
          thumb: data.profile_photo,
          countryCode: data.country,
          tags: this.formartTags(data.skills),
          uc_id: data.uc_id,
          id: data.provider_id,
          friend: data.friend_count && data.friend_count !== "0" ? true : false,
          actionData: {
            id: data.provider_id,
            zanNum: data.like_count,
            replyCount: data.reviewer_count,
            likeStatus: data.like_id ? true : false,
            type: 3,
            shareData: {
              title: data.name,
              thumb: data.profile_photo,
              desc: data.about_me.slice(0, 30),
              url: "/userInfo/" + data.provider_id,
            },
          },
        },
        id: data.provider_id,
        about_me: data.about_me,
        education: data.education,
        experiance: data.experiance,
        showBtns: this.showBtns,
      };
    },
  },
};
</script>
<style lang="stylus">
.nodata {
  margin: 0;

  .empty {
    width: 5rem !important;
  }
}

.narrowBox {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  align-items: flex-start;

  .left-menu {
    margin-right: 0.9rem;
    width: 330px;
    // float: left;
    display: flex;
    flex-flow: column;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 3px;

    ul {
      &.menu {
        margin: 0.9rem 0 -1px;

        li {
          border-bottom: 1px solid #e5e5e5;
          padding: 0.7rem;
          cursor: pointer;

          &:nth-child(1) {
            border-top: 1px solid #e5e5e5;
          }

          &:hover, &.active {
            opacity: 0.8;
            background: none;

            i, span {
              color: #33cc66;
            }
          }

          i {
            font-size: 22px;
            color: #777777;
          }

          span {
            float: right;
            font-size: 14px;
            color: #666666;
          }
        }
      }
    }
  }

  .right-content {
    flex: 1;
    max-width: 100%;

    .box-label {
      background: #ffffff;
      line-height: 24px;
      border: 1px solid #e5e5e5;
      border-radius: 3px;
      margin-bottom: 0.9rem;

      .label {
        border-bottom: 1px solid #e5e5e5;
        padding: 0.7rem 0.9rem 0 1.5rem;

        // display: flex;
        // flex-flow: row;
        // justify-content: space-between;
        // align-items: center;
        i {
          &.el-icon--edu, &.el-icon--dir {
            // margin-left: 0.1rem;
            border-bottom: 2px solid #33cc66;
            padding: 0 0.5rem 0.25rem;
            color: #666666;
          }

          &.el-icon--plus {
            cursor: pointer;
            float: right;
            color: #33cc66;
            font-size: 20px;
          }
        }
      }

      .content-row {
        padding: 0.9rem;
        font-size: 14px;

        &.base {
          max-height: 600px;
          overflow-y: scroll;
        }

        div {
          p {
            display: inline;
            margin: 0 !important;
            padding: 0 !important;
          }
        }

        ul {
          &.tags {
            width: 100%;
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            margin: 0 0 0.5rem;
            align-items: center;

            // justify-content: space-between;
            li {
              display: inline-block;
              padding: 0 0.5rem;
              border: 1px solid #33cc66;
              border-radius: 2rem;
              margin: 0.25rem;
              font-size: 12px;
            }
          }

          &.exper, &.edu {
            width: 100%;

            li {
              margin: 1.25rem 0;
              display: flex;
              flex-flow: column;
              line-height: 30px;

              p {
                &.label-row {
                  display: flex;
                  flex-flow: row;
                }

                .title {
                  flex: 1;
                  font-size: 15px;
                  color: #000000;
                  cursor: text;
                }

                .btns {
                  // width: 50px;
                  text-align: right;

                  i {
                    margin: 0 0.5rem;
                    font-size: 16px;
                    cursor: pointer;
                    color: #666666;

                    &:hover {
                      opacity: 0.8;
                    }
                  }
                }
              }

              .job {
                font-size: 13px;
                color: #666666;
              }

              .time {
                color: #999999;
              }

              .desc {
                font-size: 14px;
                color: #666666;

                p {
                  margin: 0;
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
